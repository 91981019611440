import { GatsbyLinkProps, Link } from "gatsby"
import * as React from "react"
import styled from "styled-components"

interface Props {
  link: string
  filter?: any
  isInternal: boolean
  className?: string
}

// https://github.com/gatsbyjs/gatsby/issues/16682#issuecomment-718155902
type CustomGatsbyLinkProps = Omit<
  GatsbyLinkProps<Record<string, unknown>>,
  "ref"
>

const BaseAutoLink: React.FunctionComponent<CustomGatsbyLinkProps> = (
  { children, to, activeClassName, partiallyActive, ...other },
  ref
) => {
  // Tailor the following test to your environment.
  // This example assumes that any internal link (intended for Gatsby)
  // will start with exactly one slash, and that anything else is external.
  const internal = /^\/(?!\/)/.test(to)
  // Use Gatsby Link for internal links, and <a> for others
  if (internal) {
    return (
      <Link
        to={to}
        activeClassName={activeClassName}
        partiallyActive={partiallyActive}
        {...other}
        ref={ref}
      >
        {children}
      </Link>
    )
  }
  return (
    <a href={to} {...other} target="_blank" rel="noopener noreferrer" ref={ref}>
      {children}
    </a>
  )
}

const BaseAutoLinkWithRef = React.forwardRef<
  HTMLElement,
  React.ComponentProps<typeof BaseAutoLink>
>(BaseAutoLink)

export const AutoLink = styled(BaseAutoLinkWithRef)`
  text-decoration: none;
`

const BaseCustomLink: React.FunctionComponent<Props> = (
  { link, isInternal, children, filter, ...props },
  ref
) => {
  if (isInternal) {
    return (
      <Link {...props} to={link} state={{ filter: filter }} ref={ref}>
        {children}
      </Link>
    )
  }
  return (
    <a
      {...props}
      href={link}
      target="_blank"
      rel="noopener noreferrer"
      ref={ref}
    >
      {children}
    </a>
  )
}

const CustomLink = React.forwardRef<
  HTMLElement,
  React.ComponentProps<typeof BaseCustomLink>
>(BaseCustomLink)

export default CustomLink
