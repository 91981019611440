import { graphql, useStaticQuery } from "gatsby"
import React, { FunctionComponent } from "react"
import Footer from "../footer/footer"
import GlobalStyles from "../globalStyles"
import Header from "../header/header"
import "./layout.css"
import ReactNotification from "react-notifications-component"
import "react-notifications-component/dist/theme.css"
import Helmet from "react-helmet"

const Layout: FunctionComponent = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Helmet>
        <link
          href={`https://fonts.googleapis.com/css2?family=Lora&display=swap`}
          rel="stylesheet"
        />
      </Helmet>
      <ReactNotification />
      <GlobalStyles />
      <Header siteTitle={data.site.siteMetadata.title} />
      {children}
      <Footer />
    </>
  )
}

export default Layout
