import styled, { css } from "styled-components"
import { S1, T2, T3 } from "../../components/common/text"
import Facebook from "../../images/facebook.svg"
import Instagram from "../../images/instagram.svg"
import Linkedin from "../../images/linkedin.svg"
import LongArrow from "../../images/long-arrow.svg"
import Twitter from "../../images/twitter.svg"
import {
  COLORS,
  FONTS,
  IPAD_MAX_WIDTH,
  MAX_WIDTH_LAYOUT,
  MOBILE_MAX_WIDTH,
} from "../../styles"
import { VARIANTS } from "./footer"

const DESKTOP_NEWSLETTER_FORM_WIDTH = "25.8rem"
const IPAD_NEWSLETTER_FORM_WIDTH = "17.9rem"
const IPAD_NEWSLETTER_FORM_HEIGHT = 2.38
const IPAD_FOOTER_SECTION_TITLE_LINE_HEIGHT = 0.78
const IPAD_FOOTER_SECTION_TITLE_BOTTOM_MARGIN = 0.33
const MOBILE_BOTTOM_MARGINS = "2.69rem"

const getNewsletterFormColor = (variant: string): string => {
  if (variant === VARIANTS.SUCCESS) {
    return COLORS.BLACK
  } else if (variant === VARIANTS.FOCUSED) {
    return COLORS.BLUE_CHALK_VIOLET
  }
  return COLORS.WHITE
}

const mediaIconStyle = css`
  width: 20px;
  height: 20px;
  color: ${COLORS.WHITE};
  &:hover {
    color: ${COLORS.BLUE_CHALK_VIOLET};
  }
`

export default {
  Container: styled.footer`
    background-color: ${COLORS.HOLLY_GREEN};
    width: 100%;
  `,
  Footer: styled.div`
    max-width: ${MAX_WIDTH_LAYOUT}px;
    margin: 0 auto;
    padding: 80px 150px;

    @media screen and (max-width: ${MOBILE_MAX_WIDTH}px) {
      padding: 40px 30px 35px 30px;
    }
  `,
  TopContainer: styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    @media screen and (max-width: ${MOBILE_MAX_WIDTH}px) {
      flex-direction: column;
      align-items: flex-start;
    }
  `,
  CategoriesColumns: styled.div`
    display: flex;
    justify-content: space-around;
  `,
  CategoriesContainer: styled.div`
    display: flex;
    flex-direction: column;
    padding-right: 2rem;
    @media screen and (max-width: ${MOBILE_MAX_WIDTH}px) {
      margin-bottom: 30px;
    }
  `,
  // TODO: Add to style guide
  Category: styled(T2)`
    margin-bottom: 8px;
    text-decoration: none;
    &:hover {
      color: ${COLORS.BLUE_CHALK_VIOLET};
    }
  `,
  MediaContainer: styled.div`
    margin-top: 2rem;
    @media screen and (max-width: ${MOBILE_MAX_WIDTH}px) {
      margin-top: 25px;
    }
  `,
  MediaLink: styled.a`
    text-decoration: none;
    cursor: pointer;
    margin-right: 15px;
    border: 1px solid ${COLORS.WHITE};
    border-radius: 50%;
    padding: 12px 8px 4px 8px;
  `,
  Twitter: styled(Twitter)`
    ${mediaIconStyle}
  `,
  Facebook: styled(Facebook)`
    ${mediaIconStyle}
  `,
  Instagram: styled(Instagram)`
    ${mediaIconStyle}
  `,
  Linkedin: styled(Linkedin)`
    ${mediaIconStyle}
  `,
  FormContainer: styled.div`
    > :not(:last-child) {
      margin-bottom: 0.91rem;

      @media screen and (max-width: ${IPAD_MAX_WIDTH}px) {
        margin-bottom: 0.63rem;
      }
    }

    @media screen and (max-width: ${MOBILE_MAX_WIDTH}px) {
      height: calc(
        ${IPAD_FOOTER_SECTION_TITLE_LINE_HEIGHT}rem +
          ${IPAD_FOOTER_SECTION_TITLE_BOTTOM_MARGIN}rem +
          ${IPAD_NEWSLETTER_FORM_HEIGHT}rem +
          ${FONTS.IPAD_FOOTER_TEXT_LINE_HEIGHT}rem
      );
      margin-bottom: calc(
        ${MOBILE_BOTTOM_MARGINS} - ${FONTS.IPAD_FOOTER_TEXT_LINE_HEIGHT}rem
      );
    }
  `,
  NewsletterForm: styled.form<{ variant: string }>`
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${props => getNewsletterFormColor(props.variant)};
    ${props =>
      props.variant === VARIANTS.SUCCESS &&
      `background-color: ${COLORS.BLUE_CHALK_VIOLET};`}
    border: 1px solid
      ${props =>
        props.variant === VARIANTS.FOCUSED || props.variant === VARIANTS.SUCCESS
          ? `${COLORS.BLUE_CHALK_VIOLET}`
          : `${COLORS.WHITE}`};
    padding: 0.5rem 0.8rem;
    width: ${DESKTOP_NEWSLETTER_FORM_WIDTH};
    height: 3.43rem;

    @media screen and (max-width: ${IPAD_MAX_WIDTH}px) {
      padding: 0.5rem 0.55rem;
      width: ${IPAD_NEWSLETTER_FORM_WIDTH};
      height: ${IPAD_NEWSLETTER_FORM_HEIGHT}rem;
    }
  `,
  Input: styled(T2)`
    width: 100%;
    background-color: transparent;
    border: 0;
    outline: 0;
  `,
  Submit: styled.button`
    color: currentColor;
    display: flex;
    background-color: transparent;
    border: 0;
    cursor: pointer;
  `,
  LongArrow: styled(LongArrow)`
    color: currentColor;
    height: 16px;
    width: 40px;

    &:hover {
      color: ${COLORS.BLUE_CHALK_VIOLET};
    }
  `,
  Error: styled(T2)``,
  BottomContainer: styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    @media screen and (max-width: ${MOBILE_MAX_WIDTH}px) {
      flex-direction: column-reverse;
      align-items: flex-start;
    }
  `,
  DesktopCopyright: styled(T3)`
    text-align: left;
    margin-top: 3.5rem;
    @media screen and (max-width: ${MOBILE_MAX_WIDTH}px) {
      display: none;
    }
  `,
  Copyright: styled(T3)`
    text-align: left;
    @media screen and (min-width: ${MOBILE_MAX_WIDTH}px) {
      display: none;
    }
  `,
  CompanyContainer: styled.div`
    display: flex;
    flex-direction: column;
    @media screen and (max-width: ${MOBILE_MAX_WIDTH}px) {
      margin-bottom: 40px;
    }
  `,
  FooterSectionTitle: styled(S1)`
    margin-bottom: 8px;
  `,
  CompanyAddressLine: styled(T2)``,
}
