import styled, { css } from "styled-components"
import { Link } from "gatsby"
import {
  COLORS,
  FONTS,
  IPAD_MAX_WIDTH,
  IPHONE5_MAX_WIDTH,
  MOBILE_MAX_WIDTH,
  MOBILE_SIDE_MARGIN,
  Z_INDEXES,
} from "../../styles"
import Close from "../../images/close.svg"
import LongArrow from "../../images/long-arrow.svg"
import { H5, T1 } from "../../components/common/text"

const DESKTOP_BUTTON_WIDTH = "12.5rem"

export const CATEGORIES_ANIMATIONS_DURATION = {
  FADE_IN: 300,
  FADE_OUT: 150,
  ENTER_DELAY_UNIT: 50,
  EXIT_DELAY_UNIT: 25,
}
export const SEARCH_ANIMATIONS_DURATION = {
  ENTER: 200,
  EXIT: 200,
}
export const DESKTOP_MENU_WIDTH = "335px"
export const IPAD_MENU_WIDTH = "335px"

const mainSectionStyle = css`
  font-family: ${FONTS.SOHNE_LEICHT};
  font-size: 1.4rem;
  color: ${COLORS.BOTTLE_GREEN};
  margin: 1rem 0;
  font-weight: 500;
  @media screen and (max-width: ${IPAD_MAX_WIDTH}px) {
    font-size: 1.1rem;
    margin: 0.5rem 0;
    margin-bottom: 0;
  }
`

export default {
  Container: styled.div`
    display: flex;
    justify-content: center;
    padding: 40px 50px;
    background-color: ${COLORS.BEIGE};
    height: 100%;
    width: ${DESKTOP_MENU_WIDTH};
    position: fixed;
    left: 0;
    top: 0;
    z-index: ${Z_INDEXES.MENU_CONTAINER};
    overflow-y: auto;
    @media screen and (max-width: ${IPAD_MAX_WIDTH}px) {
      width: ${IPAD_MENU_WIDTH};
      height: 100%;
    }

    @media screen and (max-width: ${MOBILE_MAX_WIDTH}px) {
      padding: 20px ${MOBILE_SIDE_MARGIN}px;
      width: 100%;
      height: 100%;
    }

    > .search-enter {
      opacity: 0;
      transform: translate(100vw);
    }
    > .search-enter-active {
      opacity: 1;
      transform: none;
      transition: all ${SEARCH_ANIMATIONS_DURATION.ENTER}ms;
    }
    > .search-exit-active {
      opacity: 0;
      transform: translate(100vw);
      transition: all ${SEARCH_ANIMATIONS_DURATION.EXIT}ms;
    }
  `,
  SubContainer: styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  `,
  CloseButton: styled.button`
    background-color: transparent;
    border: 0;
    cursor: pointer;
    fill: ${COLORS.BOTTLE_GREEN};
    &:hover {
      opacity: 0.5;
    }
    margin-bottom: 60px;
    @media screen and (max-width: ${MOBILE_MAX_WIDTH}px) {
      position: absolute;
      right: 0px;
    }
  `,
  Close: styled(Close)`
    height: 16px;
    width: 16px;
  `,
  ContainerButton: styled.div`
    display: flex;
    align-items: center;
    width: ${DESKTOP_BUTTON_WIDTH};

    @media screen and (max-width: ${MOBILE_MAX_WIDTH}px) {
      cursor: pointer;
    }
  `,
  MenuContainer: styled.div`
    margin-bottom: 15%;
  `,
  Title: styled(H5)`
    margin-bottom: 6px;
  `,
  TitleLink: styled(H5)`
    margin-bottom: 6px;
    :hover {
      text-decoration: underline;
    }
  `,
  Navigation: styled.div`
    display: flex;
    flex-direction: column;
  `,

  Category: styled(T1)`
    display: block;
    text-decoration: none;
    margin-bottom: 6px;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  `,
  Form: styled.form`
    align-items: center;
    border-bottom: 1px ${COLORS.BOTTLE_GREEN} solid;
    bottom: 30px;
    display: none;
    font-size: 1.2rem;
    position: absolute;
    width: 100%;
    @media screen and (max-width: ${MOBILE_MAX_WIDTH}px) {
      display: flex;
    }
    @media screen and (max-width: ${IPHONE5_MAX_WIDTH}px) {
      position: relative;
    }
  `,
  Input: styled(T1)`
    padding: 0px;
    width: 100%;
    background-color: transparent;
    border: 0;
    outline: 0;
  `,
  Submit: styled.button`
    background-color: transparent;
    border: 0;
    cursor: pointer;
  `,
  LongArrow: styled(LongArrow)`
    color: ${COLORS.BOTTLE_GREEN};
  `,
  MainLink: styled(Link)`
    ${mainSectionStyle}
    text-decoration: none;
    width: fit-content;

    &:hover {
      text-decoration: underline;
    }
  `,
}
