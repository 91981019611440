import styled from "styled-components"
import { FONTS, MOBILE_MAX_WIDTH, MOBILE_SIDE_MARGIN } from "../../styles"
import { AutoLink } from "../link"

export default {
  Results: styled.div`
    max-height: calc(100vh - 20vh - 5rem);
    overflow: auto;
    width: 50vw;

    @media screen and (max-width: ${MOBILE_MAX_WIDTH}px) {
      width: calc(100% - ${2 * MOBILE_SIDE_MARGIN}px);
    }
  `,
  Result: styled(AutoLink)`
    display: block;
    color: inherit;
    text-decoration: none;
    border-bottom: thin rgba(0, 0, 0, 0.2) solid;
    padding: 1.3rem 0.3rem;

    &:last-child {
      border-bottom: 0;
    }
  `,
  ResultCategories: styled.div`
    font-family: ${FONTS.SUBTITLE_FONT_FAMILY};
    font-size: 0.65rem;
    font-weight: ${FONTS.SUBTITLE_FONT_WEIGHT};
    letter-spacing: 0.05rem;
    margin-bottom: 0.4rem;
    text-transform: uppercase;
  `,
  ResultCategory: styled.span`
    margin-right: 0.5rem;
  `,
  ResultTitle: styled.div`
    font-family: ${FONTS.TITLE_FONT_FAMILY};
    font-size: 1.3rem;
    margin-bottom: 0.2rem;
  `,
  ResultContent: styled.div`
    font-family: ${FONTS.SUBTITLE_FONT_FAMILY};
    font-size: 0.65rem;
    line-height: 0.8rem;
    max-width: 70%;

    @media screen and (max-width: ${MOBILE_MAX_WIDTH}px) {
      max-width: 80%;
    }
  `,
}
