import React, {
  FormEvent,
  ChangeEvent,
  useState,
  FunctionComponent,
} from "react"
import Style from "./footer.style"
import { useStaticQuery, graphql, Link } from "gatsby"
import addToMailchimp from "gatsby-plugin-mailchimp"
import dayjs from "dayjs"

export const VARIANTS = {
  FOCUSED: "focused",
  SUCCESS: "success",
}

enum mailChimpResultStatus {
  success = "success",
  error = "error",
}
interface mailchimpResponse {
  result: mailChimpResultStatus
  msg: string
}

type Category = {
  id: string
  name: string
  slug: string
}

interface Data {
  allContentfulCategory: {
    edges: {
      node: Category
    }[]
  }
  contentfulEventsPage: {
    title: string
    slug: string
  }
  contentfulExplorePage: {
    title: string
    slug: string
    publish: boolean
  }
  contentfulAboutPage: {
    title: string
  }
  contentfulCoronavirusPage: {
    menuTitle: string
    publish: boolean
  }
  allContentfulSpecialReportingPage: {
    edges: {
      node: {
        name: string
        publish: boolean
      }
    }[]
  }
}

const Footer: FunctionComponent = () => {
  const [email, setEmail] = useState<string>("")
  const [isEmailInputFocused, setIsEmailInputFocused] = useState<boolean>(false)
  const [isSuccess, setIsSuccess] = useState<boolean>(false)
  const [isError, setIsError] = useState<boolean>(false)

  const data = useStaticQuery<Data>(graphql`
    query OrderedCategoriesQuery {
      allContentfulCategory(sort: { fields: [order], order: ASC }) {
        edges {
          node {
            id
            name
            slug
          }
        }
      }
      contentfulEventsPage {
        title
        slug
      }
      contentfulExplorePage {
        title
        slug
        publish
      }
      contentfulAboutPage {
        title
      }
      contentfulCoronavirusPage {
        menuTitle
        publish
      }
      allContentfulSpecialReportingPage(filter: { publish: { eq: true } }) {
        edges {
          node {
            name
            publish
          }
        }
      }
    }
  `)

  const categories = data.allContentfulCategory.edges.map(
    (edge: { node: Category }) => edge.node
  )
  const aboutPage = data.contentfulAboutPage

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value)
    setIsError(false)
  }

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault()
    if (email.length <= 0) {
      setIsError(true)
      return
    }

    let response: mailchimpResponse
    try {
      response = await addToMailchimp(email)
    } catch {
      setIsError(true)
      return
    }

    if (response.result === mailChimpResultStatus.success) {
      setIsSuccess(true)
    } else if (response.result === mailChimpResultStatus.error) {
      setIsError(true)
    }
  }

  return (
    <Style.Container>
      <Style.Footer>
        <Style.TopContainer>
          <Style.FormContainer>
            <Style.FooterSectionTitle $textColor="white">
              Stay in the know
            </Style.FooterSectionTitle>
            <Style.NewsletterForm
              onSubmit={handleSubmit}
              onClick={() => setIsSuccess(false)}
              variant={
                isSuccess
                  ? VARIANTS.SUCCESS
                  : isEmailInputFocused
                  ? VARIANTS.FOCUSED
                  : ""
              }
            >
              <Style.Input
                as="input"
                $textColor="white"
                type="email"
                placeholder="Email Address"
                onChange={handleChange}
                onBlur={() => {
                  setIsEmailInputFocused(false)
                  setIsError(false)
                }}
                onClick={() => setIsError(false)}
                onFocus={() => setIsEmailInputFocused(true)}
                disabled={isSuccess}
                title={""}
                value={isSuccess ? "Thank You!" : email}
              />
              {!isSuccess && (
                <Style.Submit type="submit" formNoValidate>
                  <Style.LongArrow />
                </Style.Submit>
              )}
            </Style.NewsletterForm>
            {isError && (
              <Style.Error $textColor="white">
                Please enter a valid email
              </Style.Error>
            )}
            <Style.DesktopCopyright $textColor="white">
              © {dayjs().format("YYYY")} All Rights Reserved Privacy Policy
            </Style.DesktopCopyright>
          </Style.FormContainer>
          <Style.CategoriesColumns>
            <Style.CategoriesContainer>
              {categories.slice(0, 3).map(category => (
                <Style.Category
                  $textColor="white"
                  as={Link}
                  key={category.id}
                  to={`/${category.slug}`}
                >
                  {category.name}
                </Style.Category>
              ))}
            </Style.CategoriesContainer>
            <Style.CategoriesContainer>
              {categories.slice(3).map(category => (
                <Style.Category
                  $textColor="white"
                  as={Link}
                  key={category.id}
                  to={`/${category.slug}`}
                >
                  {category.name}
                </Style.Category>
              ))}
              <Style.Category $textColor="white" as={Link} to={`/about`}>
                {aboutPage.title}
              </Style.Category>
            </Style.CategoriesContainer>
          </Style.CategoriesColumns>
          <Style.CompanyContainer>
            <Style.FooterSectionTitle $textColor="white">
              Fifth Avenue Association
            </Style.FooterSectionTitle>
            <Style.CompanyAddressLine $textColor="white">
              16 East 52nd Street, Suite 302
            </Style.CompanyAddressLine>
            <Style.CompanyAddressLine $textColor="white">
              New York, NY 10022
            </Style.CompanyAddressLine>
            <Style.CompanyAddressLine $textColor="white">
              212-265-1310
            </Style.CompanyAddressLine>
            <Style.MediaContainer>
              <Style.MediaLink
                href={"https://www.instagram.com/fifthavenue/"}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Style.Instagram />
              </Style.MediaLink>
              <Style.MediaLink
                href={"https://twitter.com/FifthAvenueNYC_"}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Style.Twitter />
              </Style.MediaLink>
              <Style.MediaLink
                href={"https://www.facebook.com/FifthAvenueNYC"}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Style.Facebook />
              </Style.MediaLink>
            </Style.MediaContainer>
          </Style.CompanyContainer>
        </Style.TopContainer>
        <Style.BottomContainer>
          <Style.Copyright $textColor="white">
            © {dayjs().format("YYYY")} All Rights Reserved Privacy Policy
          </Style.Copyright>
        </Style.BottomContainer>
      </Style.Footer>
    </Style.Container>
  )
}

export default Footer
