import { useEffect } from "react"

const useCloseOnEscape = (closeFunction: () => void) => {
  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.key !== "Escape") return

    closeFunction()
  }

  return useEffect(() => {
    document.addEventListener("keydown", handleKeyDown)

    return () => document.removeEventListener("keydown", handleKeyDown)
  }, [])
}

export default useCloseOnEscape
