import React, {
  useRef,
  useState,
  FormEvent,
  ChangeEvent,
  useEffect,
} from "react"
import Style from "./search.style"
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore elasticlunr is not typed yet
import { Index } from "elasticlunr"
import { useStaticQuery, graphql } from "gatsby"
import SearchResult, { Result } from "./searchResult"
import useCloseOnEscape from "../useCloseOnEscape"

interface QueryResult {
  ref: string
}

interface Props {
  close: () => void
  onChange?: (query: string) => void
  defaultValue?: string
}

const Search = ({ close, onChange, defaultValue }: Props) => {
  const data = useStaticQuery(graphql`
    query SearchIndexQuery {
      siteSearchIndex {
        index
      }
    }
  `)

  const index = useRef(Index.load(data.siteSearchIndex.index))
  const [results, setResults] = useState<Result[]>([])
  const [query, setQuery] = useState<string>("")

  useEffect(() => {
    return () => close()
  }, [])

  useCloseOnEscape(close)

  const search = (currentQuery: string) => {
    setResults(
      index.current
        .search(currentQuery, { expand: true })
        .map(({ ref }: QueryResult) => index.current.documentStore.getDoc(ref))
    )
  }

  useEffect(() => {
    if (!defaultValue || defaultValue.length === 0) return
    setQuery(defaultValue)
    search(defaultValue)
  }, [defaultValue])

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setQuery(event.target.value)
    onChange && onChange(event.target.value)
    search(event.target.value)
  }

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault()
    search(query)
  }

  return (
    <Style.Container>
      <Style.CloseButton onClick={close}>
        <Style.Close />
      </Style.CloseButton>
      <Style.Form onSubmit={handleSubmit}>
        <Style.Input
          defaultValue={defaultValue}
          type="text"
          placeholder="Search"
          onChange={handleChange}
          autoFocus
        />
        <Style.Submit type="submit">
          <Style.LongArrow />
        </Style.Submit>
      </Style.Form>
      <SearchResult results={results} />
      {results.length === 0 && query.length > 0 && (
        <Style.NoResult>There is no result for this query.</Style.NoResult>
      )}
    </Style.Container>
  )
}

export default Search
