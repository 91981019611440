import React, {
  useState,
  FormEvent,
  ChangeEvent,
  useEffect,
  useRef,
  FunctionComponent,
} from "react"
import { CSSTransition } from "react-transition-group"
import Style, { SEARCH_ANIMATIONS_DURATION } from "./menu.style"
import { useClickAway } from "react-use"
import Search from "../search/search"
import useModal from "../useModal"
import useCloseOnEscape from "../useCloseOnEscape"
import { graphql, useStaticQuery } from "gatsby"
import { AutoLink } from "../link"

interface Props {
  close: () => void
}

// interface Category {
//   node: { name: string; slug: string }
// }

interface MenuItem {
  text: string
  link: string
  hash?: string
}
interface Menu {
  title: string
  link?: string
  menuItems?: MenuItem[]
}

const menus: Menu[] = [
  {
    title: `What's Happening`,
    menuItems: [{ text: "Calendar of Events", link: "events" }],
  },
  {
    title: "Plan Your Visit",
    menuItems: [
      { text: "Itineraries", link: "itineraries" },
      {
        text: "Interactive Map",
        link: "itineraries",
        hash: "our-interactive-map",
      },
      {
        text: "Fifth Favorites",
        link: "itineraries",
        hash: "fifth-avenue-favorites",
      },
    ],
  },
  {
    title: "Directories",
    menuItems: [
      { text: "Shopping", link: "shopping" },
      { text: "Dining", link: "dining" },
      { text: "History", link: "history" },
      { text: "Culture", link: "culture" },
      { text: "Hotels", link: "hotels" },
    ],
  },
  { title: "News", link: "news" },
  {
    title: "About",
    menuItems: [{ text: "Our Team", link: "about", hash: "our-team" }],
  },
]

let menuWithSpecialPages: Menu[] = []

const Menu: FunctionComponent<Props> = ({ close }) => {
  const data = useStaticQuery(graphql`
    query SpecialPagesQuery {
      allContentfulSpecialPage {
        edges {
          node {
            slug
            seoTitle
          }
        }
      }
      contentfulSpecialReportingPage(publish: { eq: true }) {
        name
      }
    }
  `)

  let specialPages = data.allContentfulSpecialPage.edges
  const specialEvent = data.contentfulSpecialReportingPage

  if (menuWithSpecialPages.length === 0) {
    menus.map((item: Menu) => {
      const copy = { ...item }
      menuWithSpecialPages.push(copy)
    })
  }
  const [isSearchDisplayed, displaySearch] = useModal()
  const [query, setQuery] = useState<string>("")
  const ref = useRef(null)
  useClickAway(ref, close)

  useCloseOnEscape(close)

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault()
    displaySearch(true)
  }

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setQuery(event.target.value)
  }

  useEffect(() => {
    return () => {
      menuWithSpecialPages = []
      close()
    }
  }, [])

  useEffect(() => {
    if (specialEvent) {
      menuWithSpecialPages[0]["menuItems"] = menuWithSpecialPages[0][
        "menuItems"
      ]?.concat({
        text: specialEvent.name,
        link: "special-events",
      })
    }
  }, [specialEvent])

  useEffect(() => {
    if (specialPages) {
      specialPages
        .filter(({ node }: any) => node.slug !== "dummy-page")
        .map(({ node }: any) => {
          menuWithSpecialPages[0]["menuItems"] = menuWithSpecialPages[0][
            "menuItems"
          ]?.concat({
            text: node.seoTitle,
            link: node.slug,
          })
        })
    }
  }, [specialPages])

  return (
    <Style.Container ref={ref}>
      <Style.SubContainer>
        <Style.CloseButton onClick={close}>
          <Style.Close />
        </Style.CloseButton>
        <Style.Navigation>
          {menuWithSpecialPages.map((menu, index) => {
            return (
              <Style.MenuContainer key={index}>
                <Style.ContainerButton>
                  {menu.link ? (
                    <Style.TitleLink
                      as={AutoLink}
                      $textColor="green"
                      to={`/${menu.link}`}
                    >
                      {menu.title}
                    </Style.TitleLink>
                  ) : (
                    <Style.Title $textColor="green">{menu.title}</Style.Title>
                  )}
                </Style.ContainerButton>
                {menu.menuItems?.map((menuItem, index) => {
                  const to = menuItem.hash
                    ? `/${menuItem.link}#${menuItem.hash}`
                    : `/${menuItem.link}`
                  return (
                    <React.Fragment key={index}>
                      <Style.Category as={AutoLink} $textColor="green" to={to}>
                        {menuItem.text}
                      </Style.Category>
                    </React.Fragment>
                  )
                })}
              </Style.MenuContainer>
            )
          })}
        </Style.Navigation>
        <CSSTransition
          in={isSearchDisplayed}
          unmountOnExit
          classNames="search"
          timeout={{
            enter: SEARCH_ANIMATIONS_DURATION.ENTER,
            exit: SEARCH_ANIMATIONS_DURATION.EXIT,
          }}
        >
          <Search
            close={() => displaySearch(false)}
            defaultValue={query}
            onChange={setQuery}
          />
        </CSSTransition>
        <CSSTransition
          in={!isSearchDisplayed}
          unmountOnExit
          classNames="form"
          timeout={{
            exit: SEARCH_ANIMATIONS_DURATION.ENTER,
          }}
          enter={false}
        >
          <Style.Form onSubmit={handleSubmit}>
            <Style.Input
              $textColor="green"
              as="input"
              type="text"
              placeholder="Search"
              onChange={handleChange}
            />
            <Style.Submit type="submit">
              <Style.LongArrow />
            </Style.Submit>
          </Style.Form>
        </CSSTransition>
      </Style.SubContainer>
    </Style.Container>
  )
}

export default Menu
