import dayjs from "dayjs"
import React, { ComponentProps, FunctionComponent } from "react"
import styled from "styled-components"
import { COLORS, FONTS, MOBILE_MAX_WIDTH } from "../../styles"

export const BaseText = styled.div<{ $textColor?: "white" | "green" | string }>`
  color: ${props => {
    switch (props.$textColor) {
      case "white":
        return COLORS.WHITE
      case "green":
        return COLORS.BOTTLE_GREEN
      default:
        return props.$textColor || COLORS.BOTTLE_GREEN
    }
  }};
`

const BaseH2 = styled(BaseText)`
  font-family: ${FONTS.FIFTH_AVE_FONT_FAMILY};
  font-size: 40px;
  line-height: 1.15;
  letter-spacing: 4px;
  @media screen and (max-width: ${MOBILE_MAX_WIDTH}px) {
    font-size: 38px;
    line-height: 0.9;
    letter-spacing: 2.6px;
    width: 30px;
    margin-bottom: 7px;
  }
`

const H2Container = styled.div`
  margin-top: -7px;
  @media screen and (max-width: ${MOBILE_MAX_WIDTH}px) {
    margin-top: -1px;
  }
`

export const H1 = styled(BaseText).attrs({ as: "h1" })`
  font-family: ${FONTS.FIFTH_AVE_FONT_FAMILY};
  font-size: 65px;
  line-height: 0.69;
  letter-spacing: 4.23px;
  @media screen and (max-width: ${MOBILE_MAX_WIDTH}px) {
    font-size: 55px;
    line-height: 0.82;
    letter-spacing: 3.58px;
  }
`

export const H2: FunctionComponent<{
  date: Date
  textColor?: string
} & ComponentProps<typeof BaseH2>> = ({
  className,
  date,
  textColor,
  ...props
}) => (
  <H2Container className={className}>
    <BaseH2 $textColor={textColor} {...props}>
      {dayjs(date).format("MM")}
    </BaseH2>
    <BaseH2 $textColor={textColor} {...props}>
      {dayjs(date).format("DD")}
    </BaseH2>
    <BaseH2 $textColor={textColor} {...props}>
      {dayjs(date).format("YY")}
    </BaseH2>
  </H2Container>
)

export const H3 = styled(BaseText).attrs({ as: "h3" })`
  font-family: ${FONTS.SOHNE_FONT_FAMILY};
  font-weight: 300;
  font-size: 48px;
  line-height: 1.08;
  @media screen and (max-width: ${MOBILE_MAX_WIDTH}px) {
    font-size: 38px;
    line-height: 1.05;
    letter-spacing: -0.38px;
  }
`

export const H4 = styled(BaseText).attrs({ as: "h4" })`
  font-family: ${FONTS.SOHNE_FONT_FAMILY};
  font-weight: 300;
  font-size: 30px;
  line-height: 1.2;
  letter-spacing: -0.3px;
`

export const H5 = styled(BaseText)`
  font-family: ${FONTS.SOHNE_FONT_FAMILY};
  font-weight: 300;
  font-size: 24px;
  line-height: 1.25;
  letter-spacing: -0.24px;
`

export const Q1 = styled(BaseText)`
  font-family: ${FONTS.LORA};
  font-weight: 400;
  font-size: 24px;
  line-height: 1.5;
  letter-spacing: 0.24px;
`
export const Q2 = styled(BaseText)`
  font-family: ${FONTS.LORA};
  font-weight: 300;
  font-size: 20px;
  line-height: 1.44;
`

export const S1 = styled(BaseText)`
  font-family: ${FONTS.SOHNE_FONT_FAMILY};
  font-weight: 600;
  font-size: 12px;
  line-height: 1.5;
  letter-spacing: 1.44px;
  margin-bottom: 5px;
  text-transform: uppercase;
`

export const S2 = styled(BaseText)`
  font-family: ${FONTS.SOHNE_FONT_FAMILY};
  font-weight: 600;
  font-size: 15px;
  line-height: 1.33;
  letter-spacing: 1.8px;
  text-transform: uppercase;
`

export const T1 = styled(BaseText)`
  font-family: ${FONTS.SOHNE_FONT_FAMILY};
  font-weight: 300;
  font-size: 15px;
  line-height: 1.33;
  letter-spacing: 0.15px;
`

export const T2 = styled(BaseText)`
  font-family: ${FONTS.SOHNE_FONT_FAMILY};
  font-weight: 300;
  font-size: 13px;
  line-height: 1.38;
  letter-spacing: 0.13px;
`

export const T3 = styled(BaseText)`
  font-family: ${FONTS.SOHNE_FONT_FAMILY};
  font-weight: 300;
  font-size: 10px;
  line-height: 1.8;
  letter-spacing: 0.1px;
`

export const T4 = styled(BaseText)`
  font-family: ${FONTS.SOHNE_FONT_FAMILY};
  font-weight: 400;
  font-size: 12px;
  line-height: 1.25;
  letter-spacing: 1.44px;
  text-transform: uppercase;
`

export const HotelTitle = styled(BaseText).attrs({ as: "h1" })`
  font-family: ${FONTS.SOHNE_FONT_FAMILY};
  font-weight: 300;
  font-size: 48px;
  line-height: 1.08;
  @media screen and (max-width: ${MOBILE_MAX_WIDTH}px) {
    font-size: 38px;
    line-height: 1.05;
    letter-spacing: -0.38px;
  }
`
