import styled from "styled-components"
import { Link } from "gatsby"
import { COLORS, FONTS, IPAD_MAX_WIDTH, MOBILE_MAX_WIDTH } from "../../styles"
import Menu from "../../images/menu.svg"
import Search from "../../images/search.svg"
import { DESKTOP_MENU_WIDTH, IPAD_MENU_WIDTH } from "../menu/menu.style"

export const HEADER_HEIGHT = "60px"
export const DESKTOP_ANIMATIONS_DURATION = {
  ENTER: 300,
  EXIT: 250,
}

export default {
  Header: styled.header`
    background: ${COLORS.HOLLY_GREEN};
    > .menu-enter {
      opacity: 0;
      transform: translate(-${DESKTOP_MENU_WIDTH});
      @media screen and (max-width: ${IPAD_MAX_WIDTH}px) {
        transform: translate(-${IPAD_MENU_WIDTH});
      }
      @media screen and (max-width: ${MOBILE_MAX_WIDTH}px) {
        transform: translate(100vw);
      }
    }

    > .search-enter {
      opacity: 0;
      transform: translate(100vw);
    }
    > .menu-enter-active,
    > .search-enter-active {
      opacity: 1;
      transform: none;
      transition: all ${DESKTOP_ANIMATIONS_DURATION.ENTER}ms;

      @media screen and (max-width: ${MOBILE_MAX_WIDTH}px) {
        transition: all 200ms;
      }
    }
    > .menu-exit-active {
      opacity: 0;
      transform: translate(-${DESKTOP_MENU_WIDTH});
      @media screen and (max-width: ${IPAD_MAX_WIDTH}px) {
        transform: translate(-${IPAD_MENU_WIDTH});
      }
      @media screen and (max-width: ${MOBILE_MAX_WIDTH}px) {
        transform: translate(100vw);
      }
      transition: all ${DESKTOP_ANIMATIONS_DURATION.EXIT}ms;

      @media screen and (max-width: ${MOBILE_MAX_WIDTH}px) {
        transition: all 200ms;
      }
    }
    > .search-exit-active {
      opacity: 0;
      transform: translate(100vw);
      transition: all ${DESKTOP_ANIMATIONS_DURATION.EXIT}ms;
      @media screen and (max-width: ${MOBILE_MAX_WIDTH}px) {
        transition: all 200ms;
      }
    }
    top: 0px;
    position: sticky;
    z-index: 10;
  `,
  Container: styled.div`
    align-items: center;
    display: flex;
    height: ${HEADER_HEIGHT};
    justify-content: space-between;
    padding: 0 50px;
    @media screen and (max-width: ${MOBILE_MAX_WIDTH}px) {
      flex-direction: row-reverse;
      padding: 0 30px;
    }
  `,
  Title: styled.div`
    display: flex;
    margin: 0 auto;
  `,
  Link: styled(Link)`
    color: ${COLORS.BLUE_CHALK_VIOLET};
    text-decoration: none;
  `,
  Img: styled.img`
    width: 210px;
  `,
  MenuButton: styled.button`
    background-color: transparent;
    border: 0;
    cursor: pointer;
    color: ${COLORS.WHITE};

    &:hover {
      color: ${COLORS.BLUE_CHALK_VIOLET};
    }
  `,
  Menu: styled(Menu)`
    height: 13px;
    width: 14px;
  `,
  SearchButton: styled.button`
    background-color: transparent;
    border: 0;
    cursor: pointer;
    fill: ${COLORS.WHITE};

    &:hover {
      fill: ${COLORS.BLUE_CHALK_VIOLET};
    }

    @media screen and (max-width: ${MOBILE_MAX_WIDTH}px) {
      display: none;
    }
  `,
  Search: styled(Search)`
    height: 14px;
    width: 14px;
  `,
}
