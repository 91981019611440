import React from "react"
import { CSSTransition } from "react-transition-group"
import { useMatch } from "@reach/router"
import { useStaticQuery, graphql } from "gatsby"

import Style, { DESKTOP_ANIMATIONS_DURATION } from "./header.style"
import Menu from "../menu/menu"
import Search from "../search/search"
import useModal from "../useModal"
import { FullBanner, SmallBanner } from "../covid/covidBanner"
interface Props {
  siteTitle: string
}

const Header = ({ siteTitle }: Props) => {
  const data = useStaticQuery(graphql`
    query {
      contentfulHomePage(instagramSectionTitle: { ne: "Dummy" }) {
        logo {
          file {
            url
          }
        }
      }
      contentfulCoronavirusPage(titleSeo: { ne: "dummy" }) {
        publish
        title
        childContentfulCoronavirusPageLandingPageTextTextNode {
          childMarkdownRemark {
            html
          }
        }
      }
    }
  `)

  const [isMenuDisplayed, displayMenu] = useModal()
  const [isSearchDisplayed, displaySearch] = useModal()

  const isMainPage = useMatch("/")
  const isCovidPage = useMatch("/covid")
  const publishCovid = data.contentfulCoronavirusPage.publish

  return (
    <>
      {!isMainPage && !isCovidPage && publishCovid && <SmallBanner />}
      <Style.Header>
        <CSSTransition
          in={isMenuDisplayed}
          unmountOnExit
          classNames="menu"
          timeout={{
            enter: DESKTOP_ANIMATIONS_DURATION.ENTER,
            exit: DESKTOP_ANIMATIONS_DURATION.EXIT,
          }}
        >
          <Menu close={() => displayMenu(false)} />
        </CSSTransition>
        <Style.Container>
          <Style.MenuButton onClick={() => displayMenu(true)}>
            <Style.Menu />
          </Style.MenuButton>
          <Style.Title>
            <Style.Link to="/">
              <Style.Img
                src={data.contentfulHomePage.logo.file.url}
                alt={siteTitle}
              />
            </Style.Link>
          </Style.Title>
          <Style.SearchButton onClick={() => displaySearch(true)}>
            <Style.Search />
          </Style.SearchButton>
        </Style.Container>
        <CSSTransition
          in={isSearchDisplayed}
          unmountOnExit
          classNames="search"
          timeout={{
            enter: DESKTOP_ANIMATIONS_DURATION.ENTER,
            exit: DESKTOP_ANIMATIONS_DURATION.EXIT,
          }}
        >
          <Search close={() => displaySearch(false)} />
        </CSSTransition>
      </Style.Header>
      {isMainPage && !isCovidPage && publishCovid && <FullBanner />}
    </>
  )
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
