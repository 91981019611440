import { useState } from "react"

const useModal = (): [boolean, (display: boolean) => void] => {
  const [isModalDisplayed, setIsModalDisplayed] = useState<boolean>(false)

  const displayModal = (toDisplay: boolean) => {
    if (toDisplay) {
      document.body.style["overflow"] = "hidden"
    } else {
      document.body.style.removeProperty("overflow")
    }
    setIsModalDisplayed(toDisplay)
  }

  return [isModalDisplayed, displayModal]
}

export default useModal
