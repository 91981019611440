import styled from "styled-components"
import { COLORS, FONTS } from "../../styles"
import { Link } from "gatsby"

const BaseSubTitle = styled.div`
  font-family: ${FONTS.SOHNE_FONT_FAMILY};
  font-weight: 900;
  font-size: 0.83rem;
  letter-spacing: 0.044rem;
  text-transform: uppercase;
  line-height: 1.88;
  color: ${COLORS.HOLLY_GREEN};
`

const BaseText = styled.div`
  color: ${COLORS.HOLLY_GREEN};
  font-family: ${FONTS.SOHNE_FONT_FAMILY};
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.22;
`

export default {
  Background: styled.div`
    background-color: ${COLORS.HOLLY_GREEN};
    padding: 3rem;
    display: flex;
    flex-direction: column;
    align-items: center;
  `,
  Container: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 900px;
  `,
  Title: styled.div`
    color: ${COLORS.WHITE};
    font-family: ${FONTS.SOHNE_FONT_FAMILY};
    font-size: 2rem;
    font-weight: 400;
    line-height: 1.44;
    margin-bottom: 1.67rem;
  `,
  Text: styled.div`
    color: ${COLORS.WHITE};
    font-family: ${FONTS.SOHNE_FONT_FAMILY};
    font-size: 0.83rem;
    font-weight: 400;
    line-height: 1.25;
    text-align: center;
    margin-bottom: 1.67rem;
  `,
  CTA: styled(Link)`
    color: ${COLORS.WHITE};
    font-family: ${FONTS.SOHNE_FONT_FAMILY};
    font-size: 0.72rem;
    font-weight: 400;
    line-height: 1.23;
    text-decoration: none;
    margin-top: 1rem;
    :hover {
      text-decoration: underline;
    }
  `,
  SmallContainer: styled.div`
    height: fit-content;
    min-height: 120px;
    padding-top: 22px;
    background-color: ${COLORS.LIGHT_BLUE_GREY};
    display: flex;
    flex-direction: column;
    align-items: center;
  `,
  SmallTitle: styled(BaseSubTitle)`
    margin-bottom: 5px;
    text-align: center;
  `,
  SmallText: styled(BaseText)`
    margin-bottom: 10px;
    text-align: center;
  `,
  SmallCTA: styled(Link)`
    color: ${COLORS.HOLLY_GREEN};
    font-family: ${FONTS.SOHNE_FONT_FAMILY};
    font-size: 0.72rem;
    font-weight: 400;
    line-height: 1.23;
    text-decoration: none;
    :hover {
      text-decoration: underline;
    }
    margin-bottom: 5px;
  `,
}
