import React, { FunctionComponent } from "react"
import Style from "./searchResult.style"
import Truncate from "react-truncate"

interface Category {
  name: string
  slug: string
}

export interface Result {
  categories: Category[]
  content: string
  id: string
  link: string
  title: string
  type: "EVENT" | "NEWS" | "RETAILER"
}

interface Props {
  results: Result[]
}

const SearchResult: FunctionComponent<Props> = ({ results }) => (
  <Style.Results>
    {results.map(result => (
      <Style.Result to={result.link} key={result.id}>
        <Style.ResultCategories>
          {result.type === "NEWS" && (
            <Style.ResultCategory>News</Style.ResultCategory>
          )}
          {result.type === "EVENT" && (
            <Style.ResultCategory>Events</Style.ResultCategory>
          )}
          {result.categories &&
            result.categories.map(category => (
              <Style.ResultCategory key={category.slug}>
                {category.name}
              </Style.ResultCategory>
            ))}
        </Style.ResultCategories>
        <Style.ResultTitle>{result.title}</Style.ResultTitle>
        <Style.ResultContent>
          <Truncate lines={2}>{result.content}</Truncate>
        </Style.ResultContent>
      </Style.Result>
    ))}
  </Style.Results>
)

export default SearchResult
