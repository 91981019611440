import { graphql, useStaticQuery } from "gatsby"
import React, { Fragment } from "react"
import Style from "./covidBanner.style"

interface query {
  contentfulCoronavirusPage: {
    title: string
    landingPageText: {
      childMarkdownRemark: {
        html: string
      }
    }
    smallBannerSubtitle: {
      childMarkdownRemark: {
        html: string
      }
    }
    pageTitle: string
  }
}

const useBannerQuery = () =>
  useStaticQuery<query>(graphql`
    query BannerQuery {
      contentfulCoronavirusPage(titleSeo: { ne: "dummy" }) {
        title
        landingPageText {
          childMarkdownRemark {
            html
          }
        }
        pageTitle
        smallBannerSubtitle {
          childMarkdownRemark {
            html
          }
        }
      }
    }
  `)

export const FullBanner = () => {
  const { contentfulCoronavirusPage } = useBannerQuery()
  const { title, landingPageText } = contentfulCoronavirusPage
  if (
    title &&
    landingPageText &&
    landingPageText.childMarkdownRemark &&
    landingPageText.childMarkdownRemark.html
  ) {
    return (
      <Style.Background>
        <Style.Container>
          <Style.Title>{contentfulCoronavirusPage.title}</Style.Title>
          <Style.Text
            dangerouslySetInnerHTML={{
              __html:
                contentfulCoronavirusPage.landingPageText.childMarkdownRemark
                  .html,
            }}
          />
          <Style.CTA to="/atHome">See all updates ›</Style.CTA>
        </Style.Container>
      </Style.Background>
    )
  }
  return <Fragment />
}

export const SmallBanner = () => {
  const { contentfulCoronavirusPage } = useBannerQuery()
  const { pageTitle, smallBannerSubtitle } = contentfulCoronavirusPage
  if (
    pageTitle &&
    smallBannerSubtitle &&
    smallBannerSubtitle.childMarkdownRemark &&
    smallBannerSubtitle.childMarkdownRemark.html
  ) {
    return (
      <Style.SmallContainer>
        <Style.SmallTitle>{pageTitle}</Style.SmallTitle>
        <Style.SmallText
          dangerouslySetInnerHTML={{
            __html:
              contentfulCoronavirusPage.smallBannerSubtitle.childMarkdownRemark
                .html,
          }}
        />
        <Style.SmallCTA to="/atHome">See all updates ›</Style.SmallCTA>
      </Style.SmallContainer>
    )
  }
  return <Fragment />
}
