import { createGlobalStyle } from "styled-components"

export default createGlobalStyle`
  @font-face {
    font-family: "FifthAvenueSans";
    font-style: normal;
    font-weight: 400;
    src: url(${require("../fonts/FifthAveSans_20190827_3.otf")}) format("opentype");
    font-display: swap;
  }
  @font-face {
   font-family: "Sohne";
   src: url(${require("../fonts/soehne-web-leicht.eot")});
   src: url(${require("../fonts/soehne-web-leicht.eot?#iefix")}) format("embedded-opentype"),
        url(${require("../fonts/soehne-web-leicht.woff2")}) format("woff2"),
        url(${require("../fonts/soehne-web-leicht.woff")}) format("woff");
   font-weight: 300;
   font-style: normal;
   font-display: swap;
  }
  @font-face {
   font-family: "Sohne";
   src: url(${require("../fonts/soehne-web-buch.eot")});
   src: url(${require("../fonts/soehne-web-buch.eot?#iefix")}) format("embedded-opentype"),
        url(${require("../fonts/soehne-web-buch.woff2")}) format("woff2"),
        url(${require("../fonts/soehne-web-buch.woff")}) format("woff");
   font-weight: 400;
   font-style: normal;
   font-display: swap;
  }
  @font-face {
   font-family: "Sohne";
   src: url(${require("../fonts/soehne-web-buch-kursiv.eot")});
   src: url(${require("../fonts/soehne-web-buch-kursiv.eot?#iefix")}) format("embedded-opentype"),
        url(${require("../fonts/soehne-web-buch-kursiv.woff2")}) format("woff2"),
        url(${require("../fonts/soehne-web-buch-kursiv.woff")}) format("woff");
   font-weight: 400;
   font-style: italic;
   font-display: swap;
  }
  @font-face {
   font-family: "Sohne";
   src: url(${require("../fonts/soehne-web-kraftig.eot")});
   src: url(${require("../fonts/soehne-web-kraftig.eot?#iefix")}) format("embedded-opentype"),
        url(${require("../fonts/soehne-web-kraftig.woff2")}) format("woff2"),
        url(${require("../fonts/soehne-web-kraftig.woff")}) format("woff");
   font-weight: 500;
   font-style: normal;
   font-display: swap;
  }
  @font-face {
   font-family: "Sohne";
   src: url(${require("../fonts/soehne-web-halbfett.eot")});
   src: url(${require("../fonts/soehne-web-halbfett.eot?#iefix")}) format("embedded-opentype"),
        url(${require("../fonts/soehne-web-halbfett.woff2")}) format("woff2"),
        url(${require("../fonts/soehne-web-halbfett.woff")}) format("woff");
   font-weight: 600;
   font-style: normal;
   font-display: swap;
  }
  @font-face {
   font-family: "Sohne";
   src: url(${require("../fonts/soehne-web-fett.eot")});
   src: url(${require("../fonts/soehne-web-fett.eot?#iefix")}) format("embedded-opentype"),
        url(${require("../fonts/soehne-web-fett.woff2")}) format("woff2"),
        url(${require("../fonts/soehne-web-fett.woff")}) format("woff");
   font-weight: 700;
   font-style: normal;
   font-display: swap;
  }
  // TODO: remove duped fonts
  @font-face {
    font-family: "Sohne-Buch";
    src: local("Sohne-Buch"), url(${require("../fonts/soehne-web-buch.woff")}) format("woff");
    font-display: swap;
  }
  @font-face {
    font-family: "Sohne-Halbfett";
    src: local("Sohne-Halbfett"), url(${require("../fonts/soehne-web-halbfett.woff")}) format("woff");
    font-display: swap;
  }
  @font-face {
    font-family: "Sohne-Leicht";
    src: local("Sohne-Leicht"), url(${require("../fonts/soehne-web-leicht.woff")}) format("woff");
    font-display: swap;
  }
  @font-face {
     font-family: "Signifier-Regular";
     src: local("Signifier-Regular"), url(${require("../fonts/signifier-regular.woff2")}) format("woff2");
     font-display: swap;
   }
`
