import styled from "styled-components"
import {
  COLORS,
  FONTS,
  MOBILE_MAX_WIDTH,
  MOBILE_SIDE_MARGIN,
  Z_INDEXES,
} from "../../styles"
import Close from "../../images/close.svg"
import LongArrow from "../../images/long-arrow.svg"

export default {
  Container: styled.div`
    align-items: center;
    background-color: ${COLORS.FANTASY_WHITE};
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: flex-start;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: ${Z_INDEXES.SEARCH_CONTAINER};
  `,
  CloseButton: styled.button`
    position: absolute;
    top: 2rem;
    right: 3vw;
    background-color: transparent;
    border: 0;
    cursor: pointer;
    fill: ${COLORS.BLACK};

    &:hover {
      opacity: 0.5;
    }

    @media screen and (max-width: ${MOBILE_MAX_WIDTH}px) {
      right: ${MOBILE_SIDE_MARGIN}rem;
    }
  `,
  Close: styled(Close)`
    height: 16px;
    width: 16px;
  `,
  Form: styled.form`
    margin-top: 20vh;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.2rem;
    border-bottom: 1px ${COLORS.BLACK} solid;
    margin-bottom: 1.3rem;
    padding-bottom: 0.5rem;
    padding-left: 0.3rem;
    width: 50vw;

    @media screen and (max-width: ${MOBILE_MAX_WIDTH}px) {
      width: calc(100% - ${2 * MOBILE_SIDE_MARGIN}px);
      padding-left: 0;
    }
  `,
  Input: styled.input`
    color: ${COLORS.BLACK};
    font-family: ${FONTS.SOHNE_FONT_FAMILY};
    width: 50vw;
    background-color: transparent;
    border: 0;
    outline: 0;

    @media screen and (max-width: ${MOBILE_MAX_WIDTH}px) {
      width: 100%;
    }
  `,
  Submit: styled.button`
    background-color: transparent;
    border: 0;
    cursor: pointer;
    &:hover {
      opacity: 0.5;
    }
  `,
  LongArrow: styled(LongArrow)`
    color: ${COLORS.BLACK};
  `,
  NoResult: styled.div`
    font-family: ${FONTS.TITLE_FONT_FAMILY};
    font-size: 1.3rem;
  `,
}
